import { jaJP } from "@clerk/localizations";
import { ClerkApp, useUser } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	json,
	useLoaderData,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import * as Sentry from "@sentry/remix";
import { $path } from "remix-routes";
import { Button } from "~/components/ui/button";
import styles from "~/tailwind.css?url";

type ErrorContainerProps = {
	title: string;
	message: string;
	onNavigate: () => void;
};

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export const loader = (args: LoaderFunctionArgs) => {
	return rootAuthLoader(args, async () => {
		const env = {
			SENTRY_DSN: process.env.SENTRY_DSN,
			APP_ENV: process.env.APP_ENV,
		};
		return json({
			env: env,
		});
	});
};

export const Layout = ({
	children,
	env,
}: { children: React.ReactNode; env?: Record<string, string | undefined> }) => {
	return (
		<html lang="ja">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
			</head>
			<body>
				{children}
				<ScrollRestoration />
				{env && (
					<script
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{
							__html: `window.ENV = ${JSON.stringify(env)}`,
						}}
					/>
				)}
				<Scripts />
			</body>
		</html>
	);
};

const App = () => {
	const data = useLoaderData<typeof loader>();
	const { user } = useUser();
	const email = user?.emailAddresses?.[0]?.emailAddress;

	if (email) {
		Sentry.setUser({
			email: email,
		});
	} else {
		Sentry.setUser(null);
	}

	return (
		<Layout env={data.env}>
			<Outlet />
		</Layout>
	);
};

const ErrorContainer = ({
	title,
	message,
	onNavigate,
}: ErrorContainerProps) => {
	return (
		<div className="error-container m-6 p-6 bg-red-100 border border-red-400 text-red-700 rounded-lg">
			<h1 className="text-m font-bold mb-2">{title}</h1>
			<p className="text-sm mb-4">{message}</p>
			<Button
				className="text-red-700 border border-red-700 bg-white hover:bg-gray-100"
				onClick={onNavigate}
			>
				トップページへ
			</Button>
		</div>
	);
};

export const ErrorBoundary = () => {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);

	const handleNavigate = () => {
		window.location.href = $path("/");
	};

	if (isRouteErrorResponse(error)) {
		return (
			<Layout>
				<ErrorContainer
					title={error.data}
					message={`${error.status} : ${error.statusText}`}
					onNavigate={handleNavigate}
				/>
			</Layout>
		);
	}

	if (error instanceof Error) {
		return (
			<Layout>
				<ErrorContainer
					title="エラーが発生しました"
					message="申し訳ありません、予期せぬエラーが発生しました。"
					onNavigate={handleNavigate}
				/>
			</Layout>
		);
	}

	return (
		<Layout>
			<ErrorContainer
				title="エラーが発生しました"
				message="原因不明のエラーが発生しました。"
				onNavigate={handleNavigate}
			/>
		</Layout>
	);
};

export default ClerkApp(App, {
	localization: jaJP,
});
